<template>
    <div class="original-html">
        <div class="card-box">
            <card-style :data="cardInfo" :cardType="cardStyleType" :showArrow="0"></card-style>
        </div>

        <div class="welcome-text">
            您好，
            <br />
            我是{{ company || '小鹅通' }}{{ userName || '--' }}，这是我的名片。请惠存，谢谢。
        </div>

        <div class="footer-area">
            <p class="tip-text">长按识别二维码，查看详情</p>
            <div class="qrcode-img">
                <canvas id="qr_canvas" class="qrcode"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import cardStyle from '../editCard/cardStyle';
import Qrious from 'qrious';

export default {
    name: 'originalHtml',
    components: {
        cardStyle
    },
    data() {
        return {
            cardInfo: {
                url: '',
                name: '',
                position: '',
                phone: '',
                email: '',
                address: ''
            },
            cardStyleType: 1, // 名片样式类型

            userName: '',
            position: '',
            company: ''
        };
    },
    methods: {
        /**
         * 初始化数据
         */
        initData(d) {
            if (d) {
                this.cardInfo = {
                    url: d.pic_url || '',
                    name: d.user_name || '',
                    position: d.title || '',
                    phone: d.phone || '',
                    email: d.email || '',
                    address: d.address || ''
                };
                this.cardStyleType = d.card_style_type || 1;

                this.userName = d.user_name || '';
                this.position = d.title || '';
                this.company = d.company || '';
                if (d.card_url) {
                    new Qrious({
                        element: document.getElementById('qr_canvas'),
                        value: d.card_url
                    });
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
img {
    width: 100%;
    height: 100%;
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: PingFang SC, monaco, Helvetica, Microsoft Yahei, Arial, sans-serif;
    color: #353535;
    font-size: 0.24rem;
}
.original-html {
    width: 100%;
    height: auto;
    background: #ffffff;
    padding: 0.62rem 0.32rem 0.32rem;
}
.card-box {
    width: 100%;
    height: auto;
    box-shadow: 0px 0.02rem 0.3rem 0rem rgba(0, 0, 0, 0.15);
    border: 0.01rem solid rgba(0, 0, 0, 0.15);
    border-radius: 0.13rem;
    background: #fff;
    margin-bottom: 0.94rem;
}

.welcome-text {
    width: 100%;
    padding: 0.08rem 0.3rem 0.62rem;
    line-height: 0.63rem;
    margin-bottom: 0.42rem;
    font-size: 0.36rem;
    border-bottom: 0.01rem solid #ebebeb;
    text-align: justify;
    word-break: break-all;
}
.footer-area {
    display: flex;
    justify-content: space-between;
    padding: 0 0 0 0.3rem;
}
.tip-text {
    line-height: 0.42rem;
    font-size: 0.26rem;
    margin-top: 0.46rem;
    color: #333333;
}
.qrcode-img {
    width: 1.35rem;
    height: 1.35rem;
    .qrcode {
        width: 100%;
        height: auto;
    }
}
</style>
