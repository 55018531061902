<template>
    <div class="card-style">
        <div class="card-type-1" v-show="newCardType == 1">
            <div class="bg-avatar">
                <div class="avatar-wrapper">
                    <img :src="data.url || require('@/assets/mine/avatar.png')" alt="avatar" />
                </div>
            </div>
            <div class="user-info">
                <div class="text-name" @click="$emit('onEdit')">
                    <span class="name-text" :class="{ 'font-lessen': data.name.length > 6 }">
                        {{ data.name || '--' }}
                    </span>
                    <span class="arrow-icon" v-if="showArrow">
                        <img :src="require('@/assets/mine/arrow-right.svg')" alt="arrow" />
                    </span>
                </div>
                <p class="text-position">{{ data.position }}</p>
                <div class="tiny-line" v-if="data.phone">
                    <span class="tiny-icon">
                        <img :src="require('@/assets/mine/phone.svg')" alt="phone" />
                    </span>
                    <span class="tiny-text">{{ data.phone }}</span>
                </div>
                <div class="tiny-line" v-if="data.address">
                    <span class="tiny-icon">
                        <img :src="require('@/assets/mine/address.svg')" alt="address" />
                    </span>
                    <span class="tiny-text tiny-text2">{{ data.address }}</span>
                </div>
            </div>
        </div>

        <div
            class="card-type-2"
            v-show="newCardType !== 1"
            :class="{
                'card-type-3': newCardType == 3,
                'card-type-4': newCardType == 4,
                'card-type-5': newCardType == 5
            }"
        >
            <div class="first-line">
                <div class="avatar-wrapper">
                    <img :src="data.url || require('@/assets/mine/avatar.png')" alt="avatar" />
                </div>
                <div class="text-name" @click="$emit('onEdit')">
                    <span class="name-text" :class="{ 'font-reduce': data.name.length > 6 }">
                        {{ data.name || '--' }}
                    </span>
                    <span class="arrow-icon" v-if="showArrow">
                        <img :src="require('@/assets/mine/arrow-right.svg')" alt="arrow" />
                    </span>
                </div>
                <div class="text-position">{{ data.position }}</div>
            </div>
            <div class="tiny-line" v-if="data.phone">
                <span class="tiny-icon">
                    <img :src="require('@/assets/mine/phone.svg')" alt="phone" />
                </span>
                <span class="tiny-text">{{ data.phone }}</span>
            </div>
            <div class="tiny-line" v-if="data.address">
                <span class="tiny-icon">
                    <img :src="require('@/assets/mine/address.svg')" alt="address" />
                </span>
                <span class="tiny-text">{{ data.address }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'cardStyle',
    props: {
        data: {
            type: Object,
            default: () => {
                return {
                    url: '',
                    name: '',
                    position: '',
                    phone: '',
                    email: '',
                    address: ''
                };
            }
        },
        showArrow: {
            type: [Number, String],
            default: 0 // 是否显示箭头按钮，1-显示，0-不
        },
        cardType: {
            type: [Number, String],
            default: 1,
            validator: (val) => {
                return val >= 1 && val <= 5;
            }
        }
    },
    computed: {
        newCardType() {
            if (this.cardType >= 1 && this.cardType <= 5) {
                return this.cardType;
            }
            return 1;
        }
    },
    methods: {
        /**
         * 计算字符串占位大小
         * 中文 - 2位
         * 其它 - 1位
         */
        calcCharNum(str) {
            let num = 0;
            const chineseReg = /^[\u4E00-\u9FA5]+$/;
            for (let index = 0; index < str.length; index++) {
                const char = str.charAt(index);
                if (chineseReg.test(char)) {
                    num += 2;
                } else {
                    num += 1;
                }
            }
            return num;
        }
    }
};
</script>

<style lang="scss" scoped>
img {
    height: 100%;
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: PingFang SC, monaco, Helvetica, Microsoft Yahei, Arial, sans-serif;
    font-size: 0.24rem;
}
.text-name {
    display: flex;
    align-items: center;
}
.name-text {
    line-height: 0.64rem;
    font-size: 0.48rem;
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
}
.arrow-icon {
    flex-shrink: 0;
    width: 0.32rem;
    height: 0.32rem;
    margin-left: 0.08rem;
}
.tiny-line {
    margin: 0 0 0.16rem;
    line-height: 0.36rem;
    max-height: 0.72rem;
    overflow: hidden;
    display: flex;
}
.tiny-icon {
    width: 0.32rem;
    height: 0.32rem;
    margin: 0.02rem 0.16rem 0 0;
    flex-shrink: 0;
}
.tiny-text {
    flex-grow: 1;
    word-break: break-all;
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
}
.font-lessen {
    font-size: 0.23rem;
}
.font-reduce {
    font-size: 0.4rem;
}
.card-style {
    background: #fff;
    border-radius: 0.16rem;
}
.card-type-1 {
    height: 3.72rem;
    border-radius: 0.16rem;
    position: relative;
    .bg-avatar {
        position: absolute;
        top: 0;
        right: 0;
    }
    .avatar-wrapper {
        height: 3.72rem;
        img {
            border-radius: 0 0.16rem 0.16rem 0;
        }
    }
    .user-info {
        position: absolute;
        top: 0;
        left: 0;
        width: 4.32rem;
        height: 3.72rem;
        border-radius: 0.16rem 0 0 0.16rem;
        padding: 0.48rem 0.6rem 0 0.32rem;
        background-image: url('../../assets/mine/bg-1.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        outline: none;
        border: none;
        appearance: none;
    }
    .text-name {
        height: 0.64rem;
        margin-bottom: 0.04rem;
        padding-right: 0.5rem;
    }
    .text-position {
        height: 0.4rem;
        line-height: 0.4rem;
        font-size: 0.28rem;
        color: #666666;
        margin-bottom: 1rem;
        text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
    }
    .tiny-text2 {
        line-height: 0.28rem;
        max-height: 0.56rem;
        font-size: 0.2rem;
        overflow: hidden;
    }
}
.card-type-2 {
    height: 3.72rem;
    padding: 0.32rem 0.32rem 0 0.48rem;
    border-radius: 0.16rem;
    background: url('../../assets/mine/bg-2.png') no-repeat;
    background-size: cover;
    .first-line {
        padding: 0.16rem 0 0.08rem 1.44rem;
        margin-bottom: 1rem;
        position: relative;
    }
    .avatar-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 1.2rem;
        height: 1.2rem;
        img {
            border-radius: 50%;
            background: #d8d8d8;
        }
    }
    .text-name {
        height: 0.52rem;
        line-height: 0.52rem;
        font-size: 0.36rem;
        color: #2b2b2b;
    }
    .text-position {
        margin-top: 0.08rem;
        line-height: 0.36rem;
        height: 0.4rem;
        color: #2b2b2b;
        text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
    }
}
.card-type-3,
.card-type-4 {
    height: 3.72rem;
    padding: 0.32rem 0.32rem 0 0.48rem;
    border-radius: 0.16rem;
    .first-line {
        padding: 0.16rem 0 0.08rem 1.44rem;
        margin-bottom: 1rem;
        position: relative;
    }
    .avatar-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 1.2rem;
        height: 1.2rem;
        img {
            border-radius: 50%;
            background: #d8d8d8;
        }
    }
    .text-name {
        height: 0.52rem;
        line-height: 0.52rem;
        font-size: 0.36rem;
        color: #ffffff;
    }
    .text-position {
        margin-top: 0.08rem;
        line-height: 0.36rem;
        color: #ffffff;
        height: 0.4rem;
        text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
    }
    .tiny-text {
        color: #ffffff;
    }
}
.card-type-3 {
    background: url('../../assets/mine/bg-3.png') no-repeat;
    background-size: cover;
}
.card-type-4 {
    background: url('../../assets/mine/bg-4.png') no-repeat;
    background-size: cover;
}
.card-type-5 {
    height: 3.72rem;
    padding: 0.32rem 0.32rem 0 0.48rem;
    border-radius: 0.16rem;
    background: url('../../assets/mine/bg-5.png') no-repeat;
    background-size: cover;
    .first-line {
        padding: 0.16rem 1.52rem 0.08rem 0;
        margin-bottom: 1rem;
        position: relative;
    }
    .avatar-wrapper {
        position: absolute;
        top: 0;
        left: unset;
        right: 0.32rem;
        width: 1.2rem;
        height: 1.2rem;
        img {
            border-radius: 50%;
            background: #d8d8d8;
        }
    }
    .text-name {
        height: 0.52rem;
        line-height: 0.52rem;
        font-size: 0.36rem;
        color: #ffffff;
    }
    .text-position {
        margin-top: 0.08rem;
        line-height: 0.36rem;
        color: #ffffff;
        height: 0.4rem;
        text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
    }
    .tiny-text {
        color: #ffffff;
    }
}
</style>
