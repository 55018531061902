<template>
    <div class="mine-main un_select" v-show="showMain">
        <!-- 隐藏元素，用于生成海报 -->
        <section class="hidden-section">
            <div ref="originalHtml">
                <original-html ref="htmlContent"></original-html>
            </div>
        </section>

        <div class="main-content">
            <div class="above-area">
                <div class="card-box">
                    <card-style
                        :data="cardInfo"
                        :cardType="cardStyleType"
                        :showArrow="1"
                        v-permits.service
                        @onEdit="onEdit()"
                    ></card-style>
                </div>
                <div class="double-btn">
                    <button class="button button--plain" v-permits.service @click="generatePoster()">生成海报</button>
                    <button class="button button--primary" @click="sendCard()" v-if="!isInMiniprogram">分享名片</button>
                </div>
            </div>

            <div class="personal-info">
                <p class="header-text">个人信息</p>
                <div class="basic-infos">
                    <div class="info-item" @click="showWechatWork()">
                        <div class="up-line">
                            <div class="info-icon">
                                <img :src="require('@/assets/mine/info-share.svg')" alt="share" />
                            </div>
                            <p class="info-text1">加我企微</p>
                        </div>
                        <p class="info-text2 left-gap">点击查看</p>
                    </div>
                    <div class="info-item copy-btn" data-msg="邮箱已复制" :data-clipboard-text="userEmail">
                        <div class="up-line">
                            <div class="info-icon">
                                <img :src="require('@/assets/mine/info-email.svg')" alt="share" />
                            </div>
                            <p class="info-text1">邮箱</p>
                        </div>
                        <p class="info-text2" :class="{ 'left-gap': !userEmail }">
                            {{ userEmail || '--' }}
                        </p>
                    </div>
                    <div class="info-item copy-btn" data-msg="公司地址已复制" :data-clipboard-text="userAddress">
                        <div class="up-line">
                            <div class="info-icon">
                                <img :src="require('@/assets/mine/info-address.svg')" alt="share" />
                            </div>
                            <p class="info-text1">地址</p>
                        </div>
                        <p class="info-text2" :class="{ 'left-gap': !userAddress }">
                            {{ userAddress || '--' }}
                        </p>
                    </div>
                </div>

                <p class="header-text">个人简介</p>
                <pre class="summary-area" v-if="userSummary">{{ userSummary }}</pre>
                <empty-area v-if="!userSummary" v-permits.service @onEdit="onEdit()"></empty-area>

                <p class="header-text">个人照片</p>
                <div class="picture-list" v-if="picList.length > 0">
                    <div class="picture-item" v-for="item in picList" :key="item.pic_id">
                        <img :src="item.pic_url" alt="pic" />
                    </div>
                </div>
                <empty-area v-if="picList.length == 0" v-permits.service @onEdit="onEdit()"></empty-area>

                <div class="footer-area">
                    <div class="footer-line">
                        <div class="xiaoe-logo">
                            <img :src="require('@/assets/mine/logo-gray.svg')" alt="logo" />
                        </div>
                        <p class="footer-text">小鹅通提供技术支持</p>
                    </div>
                    <div class="footer-line">
                        <p class="footer-text">www.xiaoe-tech.com</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- 名片分享海报弹窗 -->
        <van-overlay :show="showPosterDialog">
            <div class="poster-wrapper" @click="showPosterDialog = false">
                <div class="dialog-box" @click.stop>
                    <div class="dialog-close">
                        <span class="close-icon" @click="showPosterDialog = false">
                            <img :src="require('@/assets/mine/close3.svg')" alt="close" />
                        </span>
                    </div>
                    <div class="poster-body" ref="posterEntity"></div>
                    <div class="share-tip">长按图片保存至相册并分享</div>
                </div>
            </div>
        </van-overlay>

        <!-- 分享提示 -->
        <van-overlay :show="showShareDialog">
            <div class="share-wrapper" @click="showShareDialog = false">
                <div class="share-line">
                    <div class="share-picture">
                        <img :src="require('@/assets/mine/share-path.png')" alt="share" />
                    </div>
                </div>
                <div class="share-text">点击右上角 … 进行分享</div>
            </div>
        </van-overlay>

        <!-- 企微二维码弹窗 -->
        <van-overlay :show="showWechatDialog">
            <div class="wechat-word-wrapper" @click="showWechatDialog = false">
                <div class="wechat-box">
                    <div class="dialog-close">
                        <span class="close-icon" @click="showWechatDialog = false">
                            <img :src="require('@/assets/mine/close3.svg')" alt="close" />
                        </span>
                    </div>
                    <div class="avatar-line">
                        <div class="avatar-img">
                            <img :src="userAvatarUrl || require('@/assets/mine/avatar.png')" alt="avatar" />
                        </div>
                    </div>
                    <p class="text-1">添加我的企业微信</p>
                    <div class="qrcode-line">
                        <div class="qrcode-img">
                            <img :src="qrcodeUrl" alt="qrcode" />
                        </div>
                    </div>
                    <div class="footer-line">
                        <div class="fingerprint">
                            <img :src="require('@/assets/mine/fingerprint.svg')" alt="finger" />
                        </div>
                        <span class="text-2">长按识别二维码或保存图片</span>
                    </div>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
import originalHtml from './originalHtml';
import cardStyle from '../editCard/cardStyle';
import emptyArea from './emptyArea';

import ClipboardJS from 'clipboard';
import html2canvas from 'html2canvas';
import { mapState } from 'vuex';

export default {
    name: 'myCard',
    components: {
        originalHtml,
        cardStyle,
        emptyArea
    },
    data() {
        return {
            showMain: false,

            cardInfo: {
                url: '',
                name: '',
                position: '',
                phone: '',
                email: '',
                address: ''
            },
            cardStyleType: 1, // 名片样式类型

            showPosterDialog: false,
            userAvatarUrl: '',
            userEmail: '',
            userAddress: '',
            userSummary: '',
            picList: [],

            showShareDialog: false,

            showWechatDialog: false,
            qrcodeUrl: '',

            clipboard: null // 粘贴板
        };
    },
    mounted() {
        this.initData();
        this.initWechatWorkSdk();
    },
    destroyed() {
        this.clipboard.destroy();
    },
    computed: {
        ...mapState(['isInMiniprogram'])
    },
    methods: {
        /**
         * 初始化当前用户数据
         */
        initData() {
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: '加载中...'
            });
            this.$ajax('get_card_detail')
                .then((res) => {
                    this.$toast.clear();
                    this.showMain = true;
                    console.log(res, 'res');
                    if (res.data && res.data.code == 0) {
                        const { pic_url } = res?.data.data;
                        res.data.data.pic_url = `/forward_admin/wework/parse_picture?pic_url=${encodeURIComponent(
                            pic_url
                        )}`;
                        console.log(res.data.data, 'res.data.data');
                        this.handleDetail(res.data.data);
                    } else {
                        this.handleDetail(null);
                        this.$toast.fail('网络错误');
                        console.error(res);
                    }
                })
                .catch((err) => {
                    this.$toast.clear();
                    this.showMain = true;
                    this.handleDetail(null);
                    this.$toast.fail('网络错误');
                    console.error(err);
                });
        },
        handleDetail(d) {
            if (d) {
                this.cardInfo = {
                    url: d.pic_url || '',
                    name: d.user_name || '',
                    position: d.title || '',
                    phone: d.phone || '',
                    email: d.email || '',
                    address: d.address || ''
                };
                this.cardStyleType = d.card_style_type || 1;

                this.userAvatarUrl = d.pic_url || '';
                this.userEmail = d.email || '';
                this.userAddress = d.address || '';
                this.userSummary = d.introduction || '';
                this.picList = Array.isArray(d.pics) ? d.pics : [];

                // originalHtml组件 初始化数据
                this.$refs.htmlContent.initData(d);

                // 设置微信分享文案
                const company = d.company || '';
                const name = d.user_name || '';
                const title = d.title || '';
                wx.ready(() => {
                    this.isInMiniprogram ||
                        wx.onMenuShareWechat({
                            title: `您好，我是${company}${title}${name}，这是我的名片，请惠存，谢谢。`, // 分享标题
                            desc: '点击查看我的名片', // 分享描述
                            link: d.card_url, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
                            imgUrl: d.pic_url // 分享图标
                        });
                    wx.onMenuShareAppMessage({
                        title: `您好，我是${company}${title}${name}，这是我的名片，请惠存，谢谢。`,
                        desc: '点击查看我的名片', // 分享描述
                        link: d.card_url, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
                        imgUrl: d.pic_url // 分享图标
                    });
                });
                // 企微二维码弹窗生成二维码canvas
                if (d.qw_code) {
                    this.qrcodeUrl = d.qw_code;
                }
                // 处理复制
                this.clipboard = new ClipboardJS('.copy-btn');
                this.clipboard.on('success', (e) => {
                    const msgText = e.trigger.getAttribute('data-msg');
                    this.$toast(msgText);
                    e.clearSelection();
                });
            }
        },
        /**
         * 通过 html2canvas 生成 Canvas 图像
         */
        createCanvas() {
            return new Promise((resolve, reject) => {
                // 获取HTML元素宽度
                let width = this.$refs.originalHtml.offsetWidth;
                const options = {
                    allowTaint: false,
                    useCORS: true,
                    width,
                    scrollY: 0,
                    scrollX: 0
                };
                html2canvas(this.$refs.originalHtml, options)
                    .then((canvas) => {
                        const srcUrl = canvas.toDataURL('image/png');
                        resolve(srcUrl);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        initWechatWorkSdk() {
            const jsApiList = ['openUserProfile', 'onMenuShareWechat', 'onMenuShareAppMessage', 'hideMenuItems'];
            const currentUrl = window.location.href.split('#')[0];
            this.$ajax('get_sdk_agent_info', {
                url: currentUrl
            }).then((res) => {
                if (res.data && res.data.code === 0) {
                    let config = res.data.data.config;
                    wx.config({
                        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来。
                        appId: config.corpid, // 必填，企业微信的corpID
                        timestamp: config.timestamp, // 必填，生成签名的时间戳
                        nonceStr: config.nonceStr, // 必填，生成签名的随机串
                        signature: config.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
                        jsApiList // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
                    });
                }
            });
        },
        /**
         * 生成海报
         */
        generatePoster() {
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: '生成中'
            });
            this.createCanvas()
                .then((srcUrl) => {
                    this.$refs.posterEntity.innerHTML = '';
                    let dataImg = new Image();
                    dataImg.src = srcUrl;
                    dataImg.className = 'poster-picture';
                    this.$refs.posterEntity.appendChild(dataImg); // 长按图片保存
                    this.$toast.clear();
                    this.showPosterDialog = true;
                })
                .catch((err) => {
                    console.error(err);
                    this.$toast.clear();
                    this.$toast.fail('生成海报失败');
                });
        },
        /**
         * 进入编辑页面
         */
        onEdit() {
            this.$toast.loading({
                duration: 1000,
                forbidClick: true,
                message: '加载中...'
            });
            this.$router.push({ name: 'editCard' });
        },
        /**
         * 发名片，弹出遮罩层提示使用企业微信分享
         */
        sendCard() {
            this.showShareDialog = true;
        },
        /**
         * 在当前页面弹出企微二维码弹窗
         */
        showWechatWork() {
            console.log('click');
            if (this.qrcodeUrl) {
                this.showWechatDialog = true;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
img {
    width: 100%;
    height: 100%;
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: PingFang SC, monaco, Helvetica, Microsoft Yahei, Arial, sans-serif;
    color: #353535;
    font-size: 0.24rem;
}
.mine-main {
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    position: relative;
    box-sizing: border-box;
}
.hidden-section {
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    & > div {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 7.5rem;
    }
}
.main-content {
    position: absolute;
    width: 100%;
    height: auto;
    z-index: 1;
}
.above-area {
    padding: 0.32rem;
    background: #fff;
}
.card-box {
    margin-bottom: 0.48rem;
    border-radius: 0.18rem;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15);
}
.double-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .button {
        flex-grow: 1;
        height: 0.8rem;
        line-height: 0.8rem;
        border-radius: 0.4rem;
        text-align: center;
        font-size: 0.28rem;
        font-weight: 500;
        appearance: none;
        outline: none;
        user-select: none;
        cursor: pointer;

        &:not(:first-child) {
            margin-left: 0.24rem;
        }
    }
}
.button--plain {
    border: 0.01rem solid #cccccc;
    background: #ffffff;
    color: #333333;
}
.button--primary {
    border: none;
    background: #1472ff;
    color: #fff;
}
.van-overlay {
    background: #0009;
}
.poster-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .dialog-box {
        width: 77%;
        max-width: 5.76rem;
    }
    .dialog-close {
        width: 100%;
        height: 0.32rem;
        margin-bottom: 0.24rem;
        display: flex;
        justify-content: flex-end;
    }
    .close-icon {
        width: 0.32rem;
        height: 0.32rem;
    }
    .poster-body {
        width: 100%;
        height: auto;
    }
    .share-tip {
        width: 100%;
        line-height: 0.36rem;
        font-size: 0.24rem;
        color: #ffffff;
        text-align: center;
        margin-top: 0.32rem;
    }
}
.share-wrapper {
    height: 100%;
    padding: 0.42rem 0.28rem;
    .share-line {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0.1rem;
    }
    .share-picture {
        width: 0.74rem;
        height: 1.28rem;
        margin-right: 0.44rem;
    }
    .share-text {
        line-height: 0.36rem;
        font-size: 0.26rem;
        color: #ffffff;
        text-align: right;
    }
}
.personal-info {
    height: auto;
    margin-top: 0.16rem;
    background: #ffffff;
    padding: 0.32rem 0;
}
.header-text {
    line-height: 0.48rem;
    font-size: 0.32rem;
    font-weight: 500;
    padding-left: 0.32rem;
}
.basic-infos {
    width: 100%;
    padding: 0.32rem 0.32rem 0.8rem;
    display: flex;
    overflow: scroll;
    &::-webkit-scrollbar {
        display: none;
        background-color: transparent;
    }
}
.info-item {
    width: auto;
    height: 1.12rem;
    margin-right: 0.24rem;
    padding: 0.16rem 0.24rem;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    border-radius: 0.08rem;
}
.up-line {
    min-width: 1.5rem;
    display: flex;
    margin-bottom: 0.08rem;
}
.info-icon {
    width: 0.32rem;
    height: 0.32rem;
    margin: 0.02rem 0.16rem 0 0;
    flex-shrink: 0;
}
.info-text1 {
    font-size: 0.24rem;
    color: #666666;
    line-height: 0.36rem;
    flex-shrink: 0;
}
.info-text2 {
    color: #333333;
    line-height: 0.36rem;
    word-break: keep-all;
    width: max-content;
    min-width: 1.64rem;
}
.left-gap {
    padding-left: 0.48rem;
}
.summary-area {
    line-height: 0.48rem;
    padding: 0.32rem 0.32rem 0.8rem;
    font-size: 0.28rem;
    color: #333;
    width: 100%;
    height: auto;
    word-break: break-all;
    white-space: pre-wrap;
}
.picture-list {
    padding: 0 0.32rem;
    margin: 0.32rem 0 0.72rem;
}
.picture-item {
    width: 6.86rem;
    height: auto;
    margin-bottom: 0.32rem;
    img {
        background: #cccccc;
        border-radius: 0.16rem;
    }
}
.footer-area {
    .footer-line {
        line-height: 0.36rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.08rem;
    }
    .xiaoe-logo {
        width: 0.28rem;
        height: 0.28rem;
        margin-right: 0.1rem;
    }
    .footer-text {
        color: #c8c8c8;
    }
}
.wechat-word-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .wechat-box {
        width: 5.76rem;
        height: 7.16rem;
        padding: 0.24rem 0 0;
        background: url('../../assets/mine/bg-add.svg') no-repeat;
        background-size: cover;
    }
    .dialog-close {
        width: 100%;
        height: 0.32rem;
        padding-right: 0.24rem;
        margin-bottom: 0.08rem;
        display: flex;
        justify-content: flex-end;
    }
    .close-icon {
        width: 0.32rem;
        height: 0.32rem;
    }
    .avatar-line {
        width: 100%;
        height: 0.96rem;
        margin-bottom: 0.56rem;
        display: flex;
        justify-content: center;
    }
    .avatar-img {
        width: 0.96rem;
        height: 0.96rem;
        img {
            border-radius: 50%;
        }
    }
    .text-1 {
        height: 0.48rem;
        line-height: 0.48rem;
        text-align: center;
        font-size: 0.32rem;
        color: #fff;
        margin-bottom: 0.32rem;
    }
    .qrcode-line {
        text-align: center;
        margin-bottom: 0.16rem;
    }
    .qrcode-img {
        width: 3.2rem;
        height: 3.2rem;
        display: inline-block;
        background: #fff;
        border-radius: 0.12rem;
        img {
            border-radius: 0.12rem;
        }
    }
    .footer-line {
        display: flex;
        justify-content: center;
    }
    .fingerprint {
        width: 0.32rem;
        height: 0.32rem;
        margin-right: 0.08rem;
    }
    .text-2 {
        color: #fff;
        line-height: 0.36rem;
    }
}
</style>
<style>
.poster-body .poster-picture {
    width: 100%;
    height: auto;
    border-radius: 0.16rem;
}
</style>
